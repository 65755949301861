@import "../../guideline.css";

.field {
	position: relative;
	width: 100%;

	&.open {
		z-index: 10;
	}
}

.fieldWrapper {
	display: flex;
	align-items: flex-end;

	.input {
		width: 100%;
		position: relative;
	}

	.noteWrapper {
		flex-shrink: 0;
		margin-left: calc(var(--design-unit) * 2px);

		button {
			height: 32px;
		}
	}
}

.note {
	@mixin body;
	color: var(--neutral-foreground-rest);
	margin-top: calc(var(--design-unit) * 1px);
	width: 300px;
}

.bottomText {
	@mixin caption;
	color: var(--neutral-foreground-hint);
	margin-top: 4px;
}

.error {
	@mixin body;
	margin-top: calc(var(--design-unit) * 1px);
	color: var(--error-foreground);
}

.blockLabel {
	display: block;
	margin-bottom: calc(var(--design-unit) * 2px);
}

.label {
	@mixin caption;
	position: absolute;
	top: 0;
	left: 0;
	color: var(--neutral-foreground-hint);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;

	transition-property: top, font-size, line-height;
	transition-duration: 200ms;
	pointer-events: none;
	z-index: 1;
}

.field.labelToPlaceholder:not(.hasFocus) .label {
	top: 18px;
	line-height: 24px;
	font-size: 14px;
}

.normalLabel {
	@mixin caption;
	line-height: 24px;
	color: var(--neutral-foreground-hint);
}

.inputWrapper {
	display: flex;
	background: transparent;
	transition: border-color 0.2s, background-color 0.5s;
	position: relative;
	width: 100%;
	user-select: none;

	&.bordered {
		background: padding-box linear-gradient(var(--neutral-fill-rest), var(--neutral-fill-rest)),
			border-box var(--neutral-stroke-input-rest);
		border: calc(var(--stroke-width) * 1px) solid transparent;
		border-radius: calc(var(--control-corner-radius) * 1px);
		min-height: calc((var(--base-height-multiplier)) * var(--design-unit) * 1px);
		height: auto;

		&:hover:not(.disabled):not(.hasFocus) {
			background: padding-box linear-gradient(var(--neutral-fill-hover), var(--neutral-fill-hover)),
				border-box var(--neutral-stroke-input-hover);
		}

		&:not(.disabled):active::after {
			left: 50%;
			width: 40%;
			transform: translateX(-50%);
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:not(.disabled):focus-within::after {
			left: calc(var(--stroke-width) * -1px);
			width: calc(100% + 2px * var(--stroke-width));
			transform: none;
		}

		&:not(.disabled):active::after,
		&:not(.disabled):focus-within:not(:active)::after {
			pointer-events: none;
			content: "";
			position: absolute;
			height: 100%;
			bottom: calc(var(--stroke-width) * -1px);
			border-bottom: calc(var(--focus-stroke-width) * 1px) solid var(--accent-fill-rest);
			border-bottom-left-radius: calc(var(--control-corner-radius) * 1px);
			border-bottom-right-radius: calc(var(--control-corner-radius) * 1px);
			z-index: 2;
			transition: all 300ms cubic-bezier(0.1, 0.9, 0.2, 1) 0s;
		}

		&.hasError::after {
			border-color: var(--error-foreground) !important;
		}
	}

	&.underlined {
		border: none;
		border-bottom: 1px solid var(--neutral-fill-secondary-hint);
		height: 45px;

		&.hasFocus {
			outline: none;
			border-color: var(--accent-fill-rest);

			&:after {
				content: "";
				display: block;
				border-bottom: 2px solid var(--accent-fill-rest);
				position: absolute;
				width: 100%;
				height: 100%;
				bottom: -1px;
				pointer-events: none;
			}
		}

		&:hover {
			border-color: var(--neutral-foreground-rest);

			.actionButtons .button svg {
				fill: var(--neutral-stroke-alt-rest);
			}
		}

		&.disabled {
			border-color: var(--neutral-stroke-rest) !important;
			background: var(--neutral-stroke-rest);
		}
	}

	&.hasError {
		border-color: var(--error-foreground) !important;
	}
}

.input {
	width: 100%;
}

.actionButtons {
	z-index: var(--field-action-buttons-z-index);
	display: flex;
	align-items: center;
	gap: 4px;
	margin-right: 3px;
	box-sizing: border-box !important;

	&.underlined {
		height: 45px;
		align-items: flex-end;
		position: relative;
		top: -3px;
	}

	.actionButton {
		height: calc(var(--design-unit) * 6px + 1px);
		width: calc(var(--design-unit) * 6px + 1px);
		padding: 0 calc(var(--design-unit) * 1px);
		box-sizing: border-box !important;
	}

	.inactiveActionButton:hover {
		background: transparent !important;
	}
}
