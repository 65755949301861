@import "../../guideline.css";

/* TODO a */
.button {
	@mixin body;
	position: relative;
	box-sizing: border-box;
	outline: none;
	min-width: calc((var(--base-height-multiplier) * 2) * var(--design-unit) * 1px);
	color: var(--neutral-foreground-rest);
	border-radius: calc(var(--control-corner-radius) * 1px);
	border: calc(var(--stroke-width) * 1px) solid transparent;
	fill: currentcolor;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: calc(var(--design-unit) * 1.5px) calc((var(--design-unit) * 2) * 1px);
	white-space: nowrap;
	user-select: none;
	text-decoration: none;
	transition-property: background, border, border-color;

	&.disabled {
		opacity: var(--disabled-opacity) !important;
		cursor: default;
	}

	&.unsafeRoute {
		cursor: not-allowed;
	}

	a& {
		text-decoration: none !important;
	}

	&:focus {
		outline: none;
	}

	&:focus-visible,
	&.visibleFocus:focus {
		@mixin insetFocus;
	}

	.primaryFont {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		justify-content: center;

		> svg {
			display: inline-block;
			margin-right: calc(var(--design-unit) * 2px);
			fill: currentcolor;
			flex-shrink: 0;
		}
	}

	&.iconButton {
		min-width: auto;
		padding: calc(var(--design-unit) * 1.5px) calc(var(--design-unit) * 1.5px);

		.primaryFont > svg {
			margin: 0;
		}
	}

	> span {
		display: block;
		width: 100%;
	}
}

.button.primary {
	background: padding-box linear-gradient(var(--accent-fill-rest), var(--accent-fill-rest)),
		border-box var(--accent-stroke-control-rest);
	color: var(--accent-foreground-rest);

	&.disabled {
		background: var(--accent-fill-rest);
	}

	&:not(.disabled) {
		&:hover {
			background: padding-box linear-gradient(var(--accent-fill-hover), var(--accent-fill-hover)),
				border-box var(--accent-stroke-control-hover);
			color: var(--accent-foreground-hover);
		}

		&:active {
			background: padding-box linear-gradient(var(--accent-fill-active), var(--accent-fill-active)),
				border-box var(--accent-stroke-control-active);
			color: var(--accent-foreground-active);
		}
	}
}

.button.secondary {
	background: padding-box linear-gradient(var(--neutral-fill-rest), var(--neutral-fill-rest)),
		border-box var(--neutral-stroke-rest);
	color: var(--neutral-foreground-rest);

	&.disabled {
		background: padding-box linear-gradient(var(--neutral-fill-rest), var(--neutral-fill-rest)),
			border-box var(--neutral-stroke-rest);
	}

	&:not(.disabled) {
		&:hover {
			background: padding-box linear-gradient(var(--neutral-fill-hover), var(--neutral-fill-hover)),
				border-box var(--neutral-stroke-hover);
		}

		&:active {
			background: padding-box linear-gradient(var(--neutral-fill-active), var(--neutral-fill-active)),
				border-box var(--neutral-stroke-active);
		}
	}

	&.checked {
		background: padding-box linear-gradient(var(--accent-fill-rest), var(--accent-fill-rest)),
			border-box var(--accent-stroke-control-rest);
		color: var(--accent-foreground-rest);

		&:hover {
			background: padding-box linear-gradient(var(--accent-fill-hover), var(--accent-fill-hover)),
				border-box var(--accent-stroke-control-hover);
			color: var(--accent-foreground-hover);
		}

		&:active {
			background: padding-box linear-gradient(var(--accent-fill-active), var(--accent-fill-active)),
				border-box var(--accent-stroke-control-active);
			color: var(--accent-foreground-active);
		}
	}
}

.button.textButton {
	text-align: left;
	background: transparent;
	color: var(--neutral-foreground-rest);
	min-width: auto;
	box-shadow: none;

	.primaryFont {
		justify-content: flex-start;
	}

	&:not(.disabled) {
		&:hover {
			background: var(--body-fill-hover);
		}

		&:active {
			background: var(--body-fill-active);
			color: var(--neutral-foreground-active);
		}
	}

	&.checked {
		background: var(--body-fill-secondary-rest);

		&:hover {
			background: var(--body-fill-secondary-hover);
		}

		&:active {
			background: var(--body-fill-secondary-active);
		}
	}
}

.button.linkButton {
	@mixin link;
	background: none;
	min-width: auto;
	padding: 0;
	height: auto;
	border: none;
	box-shadow: none;

	&:hover {
		background: none;
	}
}

.button.loading {
	cursor: progress;
}

.loader {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loadedContent {
	opacity: var(--disabled-opacity) !important;
}
