.CheckCircle_wrapper__o7cYx {
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background: var(--neutral-fill-rest);
	border: 1px solid var(--neutral-stroke-alt-rest);
	display: flex;
	justify-content: center;
	align-items: center;
}

	.CheckCircle_wrapper__o7cYx svg {
		transition-property: fill;
		transition-duration: 200ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
		fill: var(--neutral-fill-rest);
	}

	.CheckCircle_wrapper__o7cYx:hover svg {
			fill: var(--neutral-stroke-alt-rest);
		}

	.CheckCircle_wrapper__o7cYx.CheckCircle_selected__JhQaa,
	.CheckCircle_wrapper__o7cYx.CheckCircle_selected__JhQaa:hover {
		background: var(--accent-fill-rest);
		border-color: var(--neutral-fill-rest);
	}

	.CheckCircle_wrapper__o7cYx.CheckCircle_selected__JhQaa svg, .CheckCircle_wrapper__o7cYx.CheckCircle_selected__JhQaa:hover svg {
			fill: var(--neutral-fill-rest);
		}

@keyframes animations_css-0__Gf3E0 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes animations_css-13__ur_p6 {
	0% {
		transform: translate3d(0px, -20px, 0px);
	}
	100% {
		transform: translate3d(0px, 0px, 0px);
	}
}

@keyframes animations_css-12__8lri0 {
	0% {
		transform: translate3d(0px, -10px, 0px);
	}
	100% {
		transform: translate3d(0px, 0px, 0px);
	}
}

@keyframes animations_css-16__vZyVO {
	0% {
		transform: translate3d(-20px, 0, 0px);
	}
	100% {
		transform: translate3d(0px, 0px, 0px);
	}
}

.animations_animateMenu__7QKeB {
	animation-name: animations_css-0__Gf3E0, animations_css-12__8lri0;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

.animations_animateList__0xQB9 {
	animation-name: animations_css-0__Gf3E0, animations_css-13__ur_p6;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

.animations_animateHorizontal__3EAGM {
	animation-name: animations_css-0__Gf3E0, animations_css-16__vZyVO;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

.animations_animateHorizontalRight__oXFUm {
	animation-name: animations_css-0__Gf3E0, animations_css-17__FeCOq;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
.PopoverButton_wrapper__ZqPQ3 *,
.PopoverButton_popover__vcOTm * {
	box-sizing: border-box;
}
.PopoverButton_popover__vcOTm {
	color: var(--neutral-foreground-rest);
	background-color: var(--neutral-fill-rest);
	padding: calc(var(--design-unit) * 4px);
	border: 1px solid var(--neutral-stroke-rest);
	border-radius: 8px;
	box-shadow: 0 0.6px 1.8px var(--shadow10), 0 3.2px 7.2px var(--shadow13);
	background: var(--body-fill-rest);
	position: absolute;
	z-index: var(--base-modal-z-index);
	left: 0;
	top: 0;
	width: 0;
	height: auto;
	outline: none;
	pointer-events: all;
	padding: calc(var(--design-unit) * 1px);
}
.PopoverButton_popover__vcOTm.PopoverButton_message___KUZn {
		padding: calc(var(--design-unit) * 3px);
	}
.PopoverButton_popover__vcOTm.PopoverButton_paddingLess__meRjb {
		padding: 0;
	}
.PopoverButton_backdrop__qN84d {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.PopoverButton_button__gH5oB {
	position: relative;
}
.PopoverButton_label__6FKE4 {
	color: var(--accent-fill-rest);
	margin-bottom: 5px;
	font-size: 12px;
}
.PopoverButton_label__6FKE4.PopoverButton_labelError__B_1Xs {
		color: var(--error-foreground);
	}
.PopoverButton_error__bhEmf {
	font-size: 14px;
	margin-top: 4px;
	color: var(--error-foreground);
	position: relative;
	top: -6px;
}
.PopoverButton_popoverButtons__KW2y2 > * {
	width: 100%;
}
.PopoverButton_chevron__h6IUF {
	margin-inline: calc(var(--design-unit) * 1px) 0 !important;
	transition: 0.3s 0s ease-out;
}
.PopoverButton_openChevron__KCkf5 {
	transform: rotateZ(180deg);
}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
.SmallLoader_bigWrapper__VHUHD {
	margin: 100px auto;
}
.SmallLoader_loaderWrapper__loKUa {
	position: relative;
	margin: 0 auto;
	width: 24px;
}
.SmallLoader_loaderWrapper__loKUa:before {
		content: "";
		display: block;
		padding-top: 100%;
	}
.SmallLoader_circular__Z_t7A {
	animation: SmallLoader_rotate__xx5nm 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.SmallLoader_path__89d9i {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	stroke: var(--accent-fill-rest);
	animation: SmallLoader_dash__vSiJu 1.5s ease-in-out infinite;
	stroke-linecap: round;
}
.SmallLoader_path__89d9i.SmallLoader_buttonTheme__e0ZcB {
		stroke: var(--accent-foreground-rest);
	}
@keyframes SmallLoader_rotate__xx5nm {
	100% {
		transform: rotate(360deg);
	}
}
@keyframes SmallLoader_dash__vSiJu {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
/* TODO a */
.Button_button__ctX9j {
	font-size: 14px;
	line-height: 20px;
	position: relative;
	box-sizing: border-box;
	outline: none;
	min-width: calc((var(--base-height-multiplier) * 2) * var(--design-unit) * 1px);
	color: var(--neutral-foreground-rest);
	border-radius: calc(var(--control-corner-radius) * 1px);
	border: calc(var(--stroke-width) * 1px) solid transparent;
	fill: currentcolor;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: calc(var(--design-unit) * 1.5px) calc((var(--design-unit) * 2) * 1px);
	white-space: nowrap;
	user-select: none;
	text-decoration: none;
	transition-property: background, border, border-color;
}
.Button_button__ctX9j.Button_disabled__wl8SD {
		opacity: var(--disabled-opacity) !important;
		cursor: default;
	}
.Button_button__ctX9j.Button_unsafeRoute__XvtfH {
		cursor: not-allowed;
	}
a.Button_button__ctX9j {
		text-decoration: none !important;
	}
.Button_button__ctX9j:focus {
		outline: none;
	}
.Button_button__ctX9j:focus-visible,
	.Button_button__ctX9j.Button_visibleFocus__7Bp42:focus {
		outline: calc(var(--focus-stroke-width) * 1px) solid var(--neutral-foreground-rest);
		box-shadow: 0 0 0 calc((var(--focus-stroke-width) - var(--stroke-width)) * 1px) var(--focus-stroke-outer) inset,
		0 0 0 calc(((var(--focus-stroke-width) + var(--stroke-width)) - var(--stroke-width)) * 1px)
			var(--focus-stroke-inner) inset !important;
		border-radius: calc(var(--control-corner-radius) * 1px);
	}
.Button_button__ctX9j .Button_primaryFont__fdyAs {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		justify-content: center;
	}
.Button_button__ctX9j .Button_primaryFont__fdyAs > svg {
			display: inline-block;
			margin-right: calc(var(--design-unit) * 2px);
			fill: currentcolor;
			flex-shrink: 0;
		}
.Button_button__ctX9j.Button_iconButton__PDkYT {
		min-width: auto;
		padding: calc(var(--design-unit) * 1.5px) calc(var(--design-unit) * 1.5px);
	}
.Button_button__ctX9j.Button_iconButton__PDkYT .Button_primaryFont__fdyAs > svg {
			margin: 0;
		}
.Button_button__ctX9j > span {
		display: block;
		width: 100%;
	}
.Button_button__ctX9j.Button_primary__HoCsS {
	background: padding-box linear-gradient(var(--accent-fill-rest), var(--accent-fill-rest)),
		border-box var(--accent-stroke-control-rest);
	color: var(--accent-foreground-rest);
}
.Button_button__ctX9j.Button_primary__HoCsS.Button_disabled__wl8SD {
		background: var(--accent-fill-rest);
	}
.Button_button__ctX9j.Button_primary__HoCsS:not(.Button_disabled__wl8SD):hover {
			background: padding-box linear-gradient(var(--accent-fill-hover), var(--accent-fill-hover)),
				border-box var(--accent-stroke-control-hover);
			color: var(--accent-foreground-hover);
		}
.Button_button__ctX9j.Button_primary__HoCsS:not(.Button_disabled__wl8SD):active {
			background: padding-box linear-gradient(var(--accent-fill-active), var(--accent-fill-active)),
				border-box var(--accent-stroke-control-active);
			color: var(--accent-foreground-active);
		}
.Button_button__ctX9j.Button_secondary__13e6u {
	background: padding-box linear-gradient(var(--neutral-fill-rest), var(--neutral-fill-rest)),
		border-box var(--neutral-stroke-rest);
	color: var(--neutral-foreground-rest);
}
.Button_button__ctX9j.Button_secondary__13e6u.Button_disabled__wl8SD {
		background: padding-box linear-gradient(var(--neutral-fill-rest), var(--neutral-fill-rest)),
			border-box var(--neutral-stroke-rest);
	}
.Button_button__ctX9j.Button_secondary__13e6u:not(.Button_disabled__wl8SD):hover {
			background: padding-box linear-gradient(var(--neutral-fill-hover), var(--neutral-fill-hover)),
				border-box var(--neutral-stroke-hover);
		}
.Button_button__ctX9j.Button_secondary__13e6u:not(.Button_disabled__wl8SD):active {
			background: padding-box linear-gradient(var(--neutral-fill-active), var(--neutral-fill-active)),
				border-box var(--neutral-stroke-active);
		}
.Button_button__ctX9j.Button_secondary__13e6u.Button_checked__jUTho {
		background: padding-box linear-gradient(var(--accent-fill-rest), var(--accent-fill-rest)),
			border-box var(--accent-stroke-control-rest);
		color: var(--accent-foreground-rest);
	}
.Button_button__ctX9j.Button_secondary__13e6u.Button_checked__jUTho:hover {
			background: padding-box linear-gradient(var(--accent-fill-hover), var(--accent-fill-hover)),
				border-box var(--accent-stroke-control-hover);
			color: var(--accent-foreground-hover);
		}
.Button_button__ctX9j.Button_secondary__13e6u.Button_checked__jUTho:active {
			background: padding-box linear-gradient(var(--accent-fill-active), var(--accent-fill-active)),
				border-box var(--accent-stroke-control-active);
			color: var(--accent-foreground-active);
		}
.Button_button__ctX9j.Button_textButton__MeO2h {
	text-align: left;
	background: transparent;
	color: var(--neutral-foreground-rest);
	min-width: auto;
	box-shadow: none;
}
.Button_button__ctX9j.Button_textButton__MeO2h .Button_primaryFont__fdyAs {
		justify-content: flex-start;
	}
.Button_button__ctX9j.Button_textButton__MeO2h:not(.Button_disabled__wl8SD):hover {
			background: var(--body-fill-hover);
		}
.Button_button__ctX9j.Button_textButton__MeO2h:not(.Button_disabled__wl8SD):active {
			background: var(--body-fill-active);
			color: var(--neutral-foreground-active);
		}
.Button_button__ctX9j.Button_textButton__MeO2h.Button_checked__jUTho {
		background: var(--body-fill-secondary-rest);
	}
.Button_button__ctX9j.Button_textButton__MeO2h.Button_checked__jUTho:hover {
			background: var(--body-fill-secondary-hover);
		}
.Button_button__ctX9j.Button_textButton__MeO2h.Button_checked__jUTho:active {
			background: var(--body-fill-secondary-active);
		}
.Button_button__ctX9j.Button_linkButton__kxwcc {
	color: var(--accent-fill-rest);
	text-decoration: none;
	cursor: pointer;
}
.Button_button__ctX9j.Button_linkButton__kxwcc svg {
		fill: var(--accent-fill-rest);
		vertical-align: middle;
	}
.Button_button__ctX9j.Button_linkButton__kxwcc:hover {
		color: var(--accent-fill-hover);
		text-decoration: underline;
		text-decoration-thickness: 1px;
	}
.Button_button__ctX9j.Button_linkButton__kxwcc:hover svg {
			fill: var(--accent-fill-hover);
		}
.Button_button__ctX9j.Button_linkButton__kxwcc {
	background: none;
	min-width: auto;
	padding: 0;
	height: auto;
	border: none;
	box-shadow: none;
}
.Button_button__ctX9j.Button_linkButton__kxwcc:hover {
		background: none;
	}
.Button_button__ctX9j.Button_loading__gXTgC {
	cursor: progress;
}
.Button_loader__K7Rf0 {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Button_loadedContent__OrUEf {
	opacity: var(--disabled-opacity) !important;
}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
.List_list__2DDNQ {
	display: flex;
	gap: calc(var(--design-unit) * 1px);
}
.List_list__2DDNQ.List_vertical__zmfkQ {
		width: 100%;
		flex-direction: column;
	}
.List_list__2DDNQ:focus {
		outline: none;
	}
.List_moreButton__6MNMn {
	margin-left: calc(var(--design-unit) * 1px);
}
.List_selectedMoreButton___B0O_::before {
		content: "";
		background-color: transparent;
		display: block;
		height: 3px;
		border-radius: 2px;
		position: absolute;
		top: auto;
		bottom: 0;
		width: 0;
		margin: 0 auto;
		transition: width 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
	}
.List_selectedMoreButton___B0O_::before {
		background-color: var(--accent-fill-rest);
		width: 15px;
	}
.List_itemWrapper__LlHXX.List_nestedLevel_1__XwFYu {
	padding-left: 34px;
}
.List_itemWrapper__LlHXX.List_horizontal__mfgGF::before {
		content: "";
		background-color: transparent;
		display: block;
		height: 3px;
		border-radius: 2px;
		position: absolute;
		top: auto;
		bottom: 0;
		width: 0;
		margin: 0 auto;
		transition: width 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
	}
.List_itemWrapper__LlHXX.List_vertical__zmfkQ::before {
		content: "";
		background: transparent;
		display: block;
		width: 3px;
		border-radius: 2px;
		position: absolute;
		right: auto;
		left: 0;
		top: 12px;
		bottom: 12px;
		transition: top 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			bottom 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
	}
.List_itemWrapper__LlHXX.List_vertical__zmfkQ {
	width: 100%;
}
.List_itemWrapper__LlHXX.List_vertical__zmfkQ.List_nestedLevel_1__XwFYu::before {
		left: 25px;
	}
.List_itemWrapper__LlHXX:active:not(.List_hasChild__v5hWM).List_single__8V2ZA::before {
		background: var(--accent-fill-rest);
	}
.List_itemWrapper__LlHXX:active:not(.List_hasChild__v5hWM) .List_item__K5C_u {
		color: var(--neutral-stroke-alt-rest);
	}
.List_itemWrapper__LlHXX:active:not(.List_hasChild__v5hWM) .List_item__K5C_u svg {
			fill: var(--neutral-stroke-alt-rest);
		}
.List_itemWrapper__LlHXX:focus-visible,
.List_itemWrapper__LlHXX.List_focused__yYk2h {
	outline: calc(var(--focus-stroke-width) * 1px) solid var(--neutral-foreground-rest);
	box-shadow: 0 0 0 calc((var(--focus-stroke-width) - var(--stroke-width)) * 1px) var(--focus-stroke-outer) inset,
		0 0 0 calc(((var(--focus-stroke-width) + var(--stroke-width)) - var(--stroke-width)) * 1px)
			var(--focus-stroke-inner) inset !important;
	border-radius: calc(var(--control-corner-radius) * 1px);
}
.List_itemWrapper__LlHXX.List_open__7cFit.List_collapsed__pmu1r {
	/* FIXME */
	background-color: var(--body-fill-active);
}
.List_itemWrapper__LlHXX.List_selected__4lXgJ.List_single__8V2ZA.List_horizontal__mfgGF::before {
		background-color: var(--accent-fill-rest);
		width: 15px;
	}
.List_itemWrapper__LlHXX.List_selected__4lXgJ.List_single__8V2ZA.List_vertical__zmfkQ::before {
		background: var(--accent-fill-rest);
		top: 8px;
		bottom: 8px;
	}
.List_item__K5C_u {
	width: 100%;
	display: flex;
	align-items: center;
}
.List_item__K5C_u a {
		display: flex;
		width: 100%;
		align-items: center;
	}
.List_chevron__SxFF_ {
	transition: 0.3s 0s ease-out;
	position: absolute;
	right: 9px;
}
.List_itemWrapper__LlHXX.List_open__7cFit .List_chevron__SxFF_ {
		transform: rotateZ(180deg);
	}
.List_subList__pkF_G {
	overflow: hidden;
	margin: 0;
	margin-top: 3px;
	padding: 0;
	display: flex;
	max-height: 0;
	transition: max-height 0.2s;
	flex-direction: column;
}
.List_subList__pkF_G.List_open__7cFit {
		max-height: 1000px;
	}
.List_checkCircle__d8VQm {
	margin-right: calc(var(--design-unit) * 2px);
}
.List_popupItem__TzjoI .List_itemWrapper__LlHXX.List_itemWrapper__LlHXX.List_itemWrapper__LlHXX.List_itemWrapper__LlHXX::before {
		height: auto;
		top: auto;
		bottom: auto;
		width: auto;
		margin: 0;
	}
.List_popupItem__TzjoI .List_itemWrapper__LlHXX.List_itemWrapper__LlHXX.List_itemWrapper__LlHXX.List_itemWrapper__LlHXX::before {
		content: "";
		background: transparent;
		display: block;
		width: 3px;
		border-radius: 2px;
		position: absolute;
		right: auto;
		left: 0;
		top: 12px;
		bottom: 12px;
		transition: top 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			bottom 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s,
			background-color 0.267s cubic-bezier(0.1, 0.25, 0.75, 0.9) 0s;
	}
.List_popupItem__TzjoI .List_itemWrapper__LlHXX.List_itemWrapper__LlHXX.List_itemWrapper__LlHXX.List_itemWrapper__LlHXX.List_selected__4lXgJ.List_single__8V2ZA::before {
		background: var(--accent-fill-rest);
		top: 8px;
		bottom: 8px;
	}

.OverflowSet_hiddenElements__RJXKL {
	position: absolute;
	left: -10000px;
}

.OverflowSet_hiddenMore__wOGi2 {
	visibility: hidden;
}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
.Field_field__MbDxr {
	position: relative;
	width: 100%;
}
.Field_field__MbDxr.Field_open__o3Qlu {
		z-index: 10;
	}
.Field_fieldWrapper__9t8cA {
	display: flex;
	align-items: flex-end;
}
.Field_fieldWrapper__9t8cA .Field_input__t94DW {
		width: 100%;
		position: relative;
	}
.Field_fieldWrapper__9t8cA .Field_noteWrapper__9CGaF {
		flex-shrink: 0;
		margin-left: calc(var(--design-unit) * 2px);
	}
.Field_fieldWrapper__9t8cA .Field_noteWrapper__9CGaF button {
			height: 32px;
		}
.Field_note__R15pb {
	font-size: 14px;
	line-height: 20px;
	color: var(--neutral-foreground-rest);
	margin-top: calc(var(--design-unit) * 1px);
	width: 300px;
}
.Field_bottomText__2SNr8 {
	font-size: 12px;
	line-height: 18px;
	color: var(--neutral-foreground-hint);
	margin-top: 4px;
}
.Field_error__LXMky {
	font-size: 14px;
	line-height: 20px;
	margin-top: calc(var(--design-unit) * 1px);
	color: var(--error-foreground);
}
.Field_blockLabel__A2VhF {
	display: block;
	margin-bottom: calc(var(--design-unit) * 2px);
}
.Field_label__7d3SO {
	font-size: 12px;
	line-height: 18px;
	position: absolute;
	top: 0;
	left: 0;
	color: var(--neutral-foreground-hint);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;

	transition-property: top, font-size, line-height;
	transition-duration: 200ms;
	pointer-events: none;
	z-index: 1;
}
.Field_field__MbDxr.Field_labelToPlaceholder__hGKF0:not(.Field_hasFocus__4OtSw) .Field_label__7d3SO {
	top: 18px;
	line-height: 24px;
	font-size: 14px;
}
.Field_normalLabel__5AFJl {
	font-size: 12px;
	line-height: 18px;
	line-height: 24px;
	color: var(--neutral-foreground-hint);
}
.Field_inputWrapper__VL_v_ {
	display: flex;
	background: transparent;
	transition: border-color 0.2s, background-color 0.5s;
	position: relative;
	width: 100%;
	user-select: none;
}
.Field_inputWrapper__VL_v_.Field_bordered__ts1nl {
		background: padding-box linear-gradient(var(--neutral-fill-rest), var(--neutral-fill-rest)),
			border-box var(--neutral-stroke-input-rest);
		border: calc(var(--stroke-width) * 1px) solid transparent;
		border-radius: calc(var(--control-corner-radius) * 1px);
		min-height: calc((var(--base-height-multiplier)) * var(--design-unit) * 1px);
		height: auto;
	}
.Field_inputWrapper__VL_v_.Field_bordered__ts1nl:hover:not(.Field_disabled__cEaVI):not(.Field_hasFocus__4OtSw) {
			background: padding-box linear-gradient(var(--neutral-fill-hover), var(--neutral-fill-hover)),
				border-box var(--neutral-stroke-input-hover);
		}
.Field_inputWrapper__VL_v_.Field_bordered__ts1nl:not(.Field_disabled__cEaVI):active::after {
			left: 50%;
			width: 40%;
			transform: translateX(-50%);
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
.Field_inputWrapper__VL_v_.Field_bordered__ts1nl:not(.Field_disabled__cEaVI):focus-within::after {
			left: calc(var(--stroke-width) * -1px);
			width: calc(100% + 2px * var(--stroke-width));
			transform: none;
		}
.Field_inputWrapper__VL_v_.Field_bordered__ts1nl:not(.Field_disabled__cEaVI):active::after,
		.Field_inputWrapper__VL_v_.Field_bordered__ts1nl:not(.Field_disabled__cEaVI):focus-within:not(:active)::after {
			pointer-events: none;
			content: "";
			position: absolute;
			height: 100%;
			bottom: calc(var(--stroke-width) * -1px);
			border-bottom: calc(var(--focus-stroke-width) * 1px) solid var(--accent-fill-rest);
			border-bottom-left-radius: calc(var(--control-corner-radius) * 1px);
			border-bottom-right-radius: calc(var(--control-corner-radius) * 1px);
			z-index: 2;
			transition: all 300ms cubic-bezier(0.1, 0.9, 0.2, 1) 0s;
		}
.Field_inputWrapper__VL_v_.Field_bordered__ts1nl.Field_hasError__ddmF7::after {
			border-color: var(--error-foreground) !important;
		}
.Field_inputWrapper__VL_v_.Field_underlined__yngk3 {
		border: none;
		border-bottom: 1px solid var(--neutral-fill-secondary-hint);
		height: 45px;
	}
.Field_inputWrapper__VL_v_.Field_underlined__yngk3.Field_hasFocus__4OtSw {
			outline: none;
			border-color: var(--accent-fill-rest);
		}
.Field_inputWrapper__VL_v_.Field_underlined__yngk3.Field_hasFocus__4OtSw:after {
				content: "";
				display: block;
				border-bottom: 2px solid var(--accent-fill-rest);
				position: absolute;
				width: 100%;
				height: 100%;
				bottom: -1px;
				pointer-events: none;
			}
.Field_inputWrapper__VL_v_.Field_underlined__yngk3:hover {
			border-color: var(--neutral-foreground-rest);
		}
.Field_inputWrapper__VL_v_.Field_underlined__yngk3:hover .Field_actionButtons__DOmyh .Field_button__4tKck svg {
				fill: var(--neutral-stroke-alt-rest);
			}
.Field_inputWrapper__VL_v_.Field_underlined__yngk3.Field_disabled__cEaVI {
			border-color: var(--neutral-stroke-rest) !important;
			background: var(--neutral-stroke-rest);
		}
.Field_inputWrapper__VL_v_.Field_hasError__ddmF7 {
		border-color: var(--error-foreground) !important;
	}
.Field_input__t94DW {
	width: 100%;
}
.Field_actionButtons__DOmyh {
	z-index: var(--field-action-buttons-z-index);
	display: flex;
	align-items: center;
	gap: 4px;
	margin-right: 3px;
	box-sizing: border-box !important;
}
.Field_actionButtons__DOmyh.Field_underlined__yngk3 {
		height: 45px;
		align-items: flex-end;
		position: relative;
		top: -3px;
	}
.Field_actionButtons__DOmyh .Field_actionButton__myXgy {
		height: calc(var(--design-unit) * 6px + 1px);
		width: calc(var(--design-unit) * 6px + 1px);
		padding: 0 calc(var(--design-unit) * 1px);
		box-sizing: border-box !important;
	}
.Field_actionButtons__DOmyh .Field_inactiveActionButton__5pcGC:hover {
		background: transparent !important;
	}

.Input_input__OPYh5 {
	width: 100%;
	height: 45px;
	padding: 16px 0 1px 0;
	font-size: 14px;
	line-height: 24px;
	color: var(--accent-foreground-rest);
	border: none;
	background: transparent;
	outline: none;
	-webkit-appearance: none;
	box-sizing: border-box !important;
}

	.Input_input__OPYh5::-ms-reveal,
	.Input_input__OPYh5::-ms-clear {
		display: none;
	}

	.Input_input__OPYh5::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	.Input_input__OPYh5.Input_disabled__qNcXp {
		color: var(--neutral-fill-secondary-hint) !important;
	}

.Input_hasFocus__li7Tp {
	outline: none;
}

.Input_bordered__s9GhA {
	height: 32px;
	padding: 0 calc(var(--design-unit) * 2px + 1px);
	color: var(--neutral-foreground-rest);
}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
.Checkbox_wrapper__cXb9x {
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
	cursor: pointer;
	user-select: none;
	width: 100%;
}
.Checkbox_wrapper__cXb9x:focus-visible {
		outline: calc(var(--focus-stroke-width) * 1px) solid var(--neutral-foreground-rest);
		box-shadow: 0 0 0 calc((var(--focus-stroke-width) - var(--stroke-width)) * 1px) var(--focus-stroke-outer) inset,
		0 0 0 calc(((var(--focus-stroke-width) + var(--stroke-width)) - var(--stroke-width)) * 1px)
			var(--focus-stroke-inner) inset !important;
		border-radius: calc(var(--control-corner-radius) * 1px);
	}
.Checkbox_wrapper__cXb9x .Checkbox_checkbox__WoGY3 {
		position: relative;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		height: 20px;
		width: 20px;
		border: calc(var(--stroke-width) * 1px) solid var(--neutral-stroke-rest);
		border-radius: calc(var(--control-corner-radius) * 1px);
		box-sizing: border-box;
		transition-property: background, border, border-color;
		transition-duration: 200ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
		overflow: hidden;
		background: var(--neutral-fill-rest);
	}
.Checkbox_wrapper__cXb9x .Checkbox_checkbox__WoGY3 .Checkbox_mark__NChOl {
			display: flex;
			align-items: center;
			justify-content: center;
		}
.Checkbox_wrapper__cXb9x .Checkbox_checkbox__WoGY3 .Checkbox_mark__NChOl svg {
				vertical-align: top !important;
				fill: transparent;
			}
.Checkbox_wrapper__cXb9x.Checkbox_checked__n7ujh .Checkbox_checkbox__WoGY3 {
			background: var(--accent-fill-rest);
			border-color: var(--accent-fill-rest);
		}
.Checkbox_wrapper__cXb9x.Checkbox_checked__n7ujh .Checkbox_mark__NChOl svg {
			fill: var(--accent-foreground-rest);
		}
.Checkbox_wrapper__cXb9x.Checkbox_checked__n7ujh .Checkbox_indeterminateMark__apR5o {
			height: 1px;
			width: calc(var(--design-unit) * 2px);
			background: var(--accent-foreground-rest);
		}
.Checkbox_wrapper__cXb9x:hover .Checkbox_checkbox__WoGY3 {
			background: var(--neutral-fill-hover);
			border-color: var(--neutral-stroke-hover);
		}
.Checkbox_wrapper__cXb9x:hover.Checkbox_checked__n7ujh .Checkbox_checkbox__WoGY3 {
				background: var(--accent-fill-hover);
				border-color: var(--accent-fill-hover);
			}
.Checkbox_wrapper__cXb9x:hover.Checkbox_checked__n7ujh .Checkbox_mark__NChOl svg {
				fill: var(--accent-foreground-hover);
			}
.Checkbox_wrapper__cXb9x:hover.Checkbox_checked__n7ujh .Checkbox_indeterminateMark__apR5o {
				background: var(--accent-foreground-hover);
			}
.Checkbox_wrapper__cXb9x:active .Checkbox_checkbox__WoGY3 {
			background: var(--neutral-fill-active);
			border-color: var(--neutral-stroke-active);
		}
.Checkbox_wrapper__cXb9x:active.Checkbox_checked__n7ujh .Checkbox_checkbox__WoGY3 {
				background: var(--accent-fill-active);
				border-color: var(--accent-fill-active);
			}
.Checkbox_wrapper__cXb9x:active.Checkbox_checked__n7ujh .Checkbox_mark__NChOl svg {
				fill: var(--accent-foreground-active);
			}
.Checkbox_wrapper__cXb9x:active.Checkbox_checked__n7ujh .Checkbox_indeterminateMark__apR5o {
				background: var(--accent-foreground-active);
			}
.Checkbox_wrapper__cXb9x.Checkbox_disabled__QcHG6 {
		opacity: var(--disabled-opacity);
		cursor: not-allowed;
	}
.Checkbox_wrapper__cXb9x.Checkbox_disabled__QcHG6 .Checkbox_indeterminateMark__apR5o {
		width: 10px;
		height: 2px;
		background: transparent;
		border-radius: 2px;
		transition: all cubic-bezier(0.4, 0, 0.23, 1) 0.2s;
	}
.Checkbox_wrapper__cXb9x > svg {
		flex-shrink: 0;
	}
.Checkbox_wrapper__cXb9x .Checkbox_label__WZ2l2 {
		margin-left: calc(var(--design-unit) * 3px);
		width: 100%;
	}
.Checkbox_wrapper__cXb9x .Checkbox_label__WZ2l2.Checkbox_emptyLabel__gj_7U {
			display: none;
		}

.Loader_loadingWrapper__HAF0P {
	position: relative;
	min-height: 175px;
	height: 100%;
}

.Loader_loadingContainer__FWs8Q {
	position: absolute;
	top: 50%;
	margin-top: -100px;
	left: 0;
	width: 100%;
	height: 24px;
	z-index: 10;
}

.Loader_loadingContainer__FWs8Q .Loader_loading__SsX9f {
		position: relative;
		width: 24px;
		height: 24px;
		padding: 13px;
		margin: 0 auto;
		background-color: #fff;
		border-radius: 24px;
		opacity: 0.7;
		text-align: center;
		box-sizing: content-box !important;
	}

.Loader_loadingContainer__FWs8Q .Loader_loading__SsX9f img {
			position: absolute;
			left: 13px;
			top: 13px;
		}

.Loader_loadingContainer__FWs8Q .Loader_loading__SsX9f .Loader_loadingText__Us_rX {
			font: 16px Tahoma, sans-serif;
			color: #04375e;
			display: inline-block;
			vertical-align: middle;
			line-height: 1.3em;
			margin: 0 15px;
		}

.LoginForm_error__zQ9Vs {
	color: var(--color-error);
}

.LoginForm_formWrapper__IkxRi {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.LoginForm_wrapper__luTLa {
	width: 100%;
}

@media screen and (min-width: 768px) {

.LoginForm_wrapper__luTLa {
		width: 370px
}
	}

.LoginForm_field__t50oz {
	margin: 15px;
}

.LoginForm_button__O9NHD {
	margin: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.LoginForm_forget__DSQXt {
	margin: 15px;
}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
@keyframes ModalPopup_scaleFadeIn__L9lKQ {
	from {
		opacity: 0;
		transform: scale(0.8);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}
.ModalPopup_backdrop__v7udK {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--shadow22);
	backdrop-filter: blur(2px);
	z-index: 999;
}
.ModalPopup_wrapper__II1TL {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: var(--base-modal-z-index);
	width: 100%;
	height: 100%;
	overflow-x: auto;
	overflow-y: auto;
	text-align: center;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	opacity: 1;
	white-space: nowrap;
}
.ModalPopup_wrapper__II1TL .ModalPopup_container__Wj7BV {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;
	}
.ModalPopup_wrapper__II1TL .ModalPopup_popup__q3BhQ {
		background: var(--neutral-fill-rest);
		position: relative;
		box-sizing: border-box;
		text-align: left;
		white-space: normal;
		z-index: 10050;
		animation: ModalPopup_scaleFadeIn__L9lKQ 0.4s ease;
		width: 100%;
		display: flex;
		margin: 0;
		color: var(--neutral-foreground-rest);
		background-color: var(--neutral-fill-rest);
		padding: calc(var(--design-unit) * 4px);
		border: 1px solid var(--neutral-stroke-rest);
		border-radius: 8px;
		box-shadow: 0 4.8px 14.4px var(--shadow18), 0 25.6px 57.6px var(--shadow22);
		border-radius: 0;
		border-width: 0;
	}
@media screen and (min-width: 768px) {
.ModalPopup_wrapper__II1TL .ModalPopup_popup__q3BhQ {
			width: 80%;
			margin: 30px 0;
			border-radius: calc(var(--layer-corner-radius) * 1px);
			border-width: 1px
	}
		}
.ModalPopup_wrapper__II1TL .ModalPopup_popup__q3BhQ * {
			box-sizing: border-box;
		}
.ModalPopup_wrapper__II1TL .ModalPopup_popupMain__qxVyL {
		width: 100%;
	}
.ModalPopup_wrapper__II1TL .ModalPopup_close__74anJ {
		cursor: pointer;
		position: absolute;
		color: var(--neutral-foreground-rest);
		display: block;
		top: 20px;
		right: 20px;
		opacity: 1;
		background: transparent;
		padding: 0;
		border: none;
		z-index: 2;
	}
.ModalPopup_wrapper__II1TL .ModalPopup_close__74anJ svg {
			fill: var(--neutral-foreground-rest);
		}
.ModalPopup_header__UtEwD {
	font-size: 20px;
	line-height: 32px;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 12px;
	padding-right: 36px;
}
.ModalPopup_content__u7DjE {
	width: 100%;
}

.Avatar_wrapper__3Mk_v {
	position: relative;
}

	.Avatar_wrapper__3Mk_v .Avatar_icon__pF_Ls {
		position: absolute;
		bottom: 0;
		right: 0;
		background: var(--color-secondary);
		width: 20px;
		height: 20px;
		border-radius: 100%;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.Avatar_wrapper__3Mk_v .Avatar_dummy__8Xi6z svg {
		fill: var(--color-accent-font) !important;
		margin: 0;
	}

	.Avatar_wrapper__3Mk_v.Avatar_size24__BnQyn .Avatar_circle__YWscc {
		width: 24px;
		height: 24px;
	}

	.Avatar_wrapper__3Mk_v.Avatar_size24__BnQyn .Avatar_circle__YWscc .Avatar_initials__UQuG8 {
			font-size: 10px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size24__BnQyn .Avatar_circle__YWscc svg {
			width: 12px;
			height: 12px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size32__zdM3t .Avatar_circle__YWscc {
		width: 32px;
		height: 32px;
	}

	.Avatar_wrapper__3Mk_v.Avatar_size32__zdM3t .Avatar_circle__YWscc .Avatar_initials__UQuG8 {
			font-size: 13px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size32__zdM3t .Avatar_circle__YWscc svg {
			width: 15px;
			height: 15px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size48__14yl0 .Avatar_circle__YWscc {
		width: 48px;
		height: 48px;
	}

	.Avatar_wrapper__3Mk_v.Avatar_size48__14yl0 .Avatar_circle__YWscc .Avatar_initials__UQuG8 {
			font-size: 18px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size48__14yl0 .Avatar_circle__YWscc svg {
			width: 20px;
			height: 20px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size64__AOOVo .Avatar_circle__YWscc {
		width: 64px;
		height: 64px;
	}

	.Avatar_wrapper__3Mk_v.Avatar_size64__AOOVo .Avatar_circle__YWscc .Avatar_initials__UQuG8 {
			font-size: 28px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size64__AOOVo .Avatar_circle__YWscc svg {
			width: 30px;
			height: 30px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size128__dCW_T .Avatar_circle__YWscc {
		width: 128px;
		height: 128px;
	}

	.Avatar_wrapper__3Mk_v.Avatar_size128__dCW_T .Avatar_circle__YWscc .Avatar_initials__UQuG8 {
			font-size: 50px;
		}

	.Avatar_wrapper__3Mk_v.Avatar_size128__dCW_T .Avatar_circle__YWscc svg {
			width: 50px;
			height: 50px;
		}

	.Avatar_wrapper__3Mk_v .Avatar_circle__YWscc {
		border-radius: 50%;
		overflow: hidden;
		perspective: 1px;
		display: inline-block;
		vertical-align: middle;
		flex-shrink: 0;
	}

	.Avatar_wrapper__3Mk_v .Avatar_image__tmOYe,
	.Avatar_wrapper__3Mk_v .Avatar_initials__UQuG8 {
		display: block;
		width: 100%;
		height: 100%;
	}

	.Avatar_wrapper__3Mk_v .Avatar_image__tmOYe {
		object-fit: cover;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.Avatar_wrapper__3Mk_v .Avatar_initials__UQuG8 {
		background: var(--accent-fill-rest);
		color: var(--accent-foreground-rest);
		display: flex;
		align-items: center;
		justify-content: center;
	}

/* Cards */
/* Dropdowns */
/* Tooltips, hover cards */
/* Modals, panels */
/* stylelint-disable */
* {
	box-sizing: border-box;
}
.Layout_is-modal-popup-open__4bb9r {
	overflow: hidden;
}
.Layout_is-modal-popup-open__4bb9r body {
		overflow: hidden;
		min-height: 100%;
	}
.Layout_modal-popup-form__pegao {
	margin: 0 50px;
}
.Layout_modal-popup-buttons__N0kbL {
	border-top: 1px solid var(--neutral-stroke-rest);
	padding: 15px !important;
	margin-top: 15px !important;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	list-style: none;
}
.Layout_modal-popup-content__N0QqU {
	min-height: 50px;
}
body {
	margin: 0;
	padding: 0;
	font-family: "Segoe UI Regular Cyrillic", "Segoe UI", "Segoe WP", Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
		Helvetica Neue, Tahoma, Arial, sans-serif;
	font-size: 14px;
	overflow-x: hidden;
	background: var(--body-fill-rest);
	color: var(--body-foreground-rest);
}
body button,
	body input,
	body textarea {
		font-family: "Segoe UI Regular Cyrillic", "Segoe UI", "Segoe WP", Oxygen, Ubuntu, Cantarell, Fira Sans,
			Droid Sans, Helvetica Neue, Tahoma, Arial, sans-serif;
	}
body ul,
	body ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}
body p {
		font-size: 14px;
	}
body h1 {
		font-weight: normal;
		font-size: 28px;
	}
body h2 {
		font-weight: normal;
		font-size: 25px;
	}
body h3 {
		font-weight: normal;
		font-size: 18px;
	}
body h5,
	body h6 {
		display: inline;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
	}
body a {
		color: var(--accent-fill-rest);
		text-decoration: none;
		cursor: pointer;
	}
body a svg {
		fill: var(--accent-fill-rest);
		vertical-align: middle;
	}
body a:hover {
		color: var(--accent-fill-hover);
		text-decoration: underline;
		text-decoration-thickness: 1px;
	}
body a:hover svg {
			fill: var(--accent-fill-hover);
		}
a {
	color: var(--accent-fill-rest);
	text-decoration: none;
}
a:hover {
		color: var(--accent-fill-hover);
	}
a:active {
		color: var(--accent-fill-active);
	}
h1 .subTitle, h2 .subTitle, h3 .subTitle {
		font-size: 14px;
		font-style: italic;
	}
h1 .subTitle p, h2 .subTitle p, h3 .subTitle p {
			margin: -0.1em 0;
		}
h1 {
	font-size: 20pt;
	margin: 0.67em 0 0.2em 0;
}
h2 {
	font-size: 15pt;
	margin: 0.67em 0 0.2em 0;
}
h3 {
	font-size: 12pt;
	margin: 0.67em 0 0.2em 0;
}
h4 {
	font-size: 12pt;
	margin: 0.67em 0 0.2em 0;
	font-weight: bold;
}
p {
	margin: 0.5em 0;
}
h1 + p,
h2 + p,
h3 + p,
ul + p,
ol + p,
div + p {
	text-indent: 0;
}
.Layout_mdTable__T9HWM {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
}
.Layout_mdTable__T9HWM tr {
		background: var(--neutral-fill-alt-rest);
	}
.Layout_mdTable__T9HWM tr:nth-child(even) {
			background: transparent;
		}
.Layout_mdTable__T9HWM tr td,
		.Layout_mdTable__T9HWM tr th {
			padding: calc(var(--design-unit) * 2px);
		}
.Layout_mdTable__T9HWM thead tr {
		background: transparent !important;
	}
.Layout_content__J5pnr {
	height: 100dvh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
.Layout_menu__2Y5Oo {
	flex-shrink: 0;
	width: 240px;
	max-width: 0;
	transition: max-width 0.2s;
	list-style: none;
	margin: 0;
	padding: 5px;
	min-height: 100vh;
	z-index: 1101;
	display: flex;
	flex-direction: column;
	gap: 3px;
	position: absolute;
	height: 40px;
}
@media screen and (min-width: 768px) {
.Layout_menu__2Y5Oo {
		position: static;
		left: 0;
		max-width: 52px;
		background: var(--body-fill-rest)
}

		.Layout_menu__2Y5Oo.Layout_collapsed__nfOd5 {
			max-width: 52px;
		}

		.Layout_menu__2Y5Oo .Layout_menuList___E7Wy {
			position: static !important;
			padding: 0 !important;
			left: 0;
		}
	}
@media screen and (min-width: 960px) {
.Layout_menu__2Y5Oo {
		max-width: 240px;
		height: 100vh
}

		.Layout_menu__2Y5Oo.Layout_collapsed__nfOd5 {
			max-width: 52px !important;
		}
	}
.Layout_menu__2Y5Oo .Layout_hamburger__YjKak {
		width: 42px;
	}
.Layout_menu__2Y5Oo .Layout_hamburger__YjKak > div {
			justify-content: center !important;
		}
.Layout_menu__2Y5Oo .Layout_menuList___E7Wy {
		position: absolute;
		left: -240px;
		top: 37px;
		padding: 5px;
	}
.Layout_menu__2Y5Oo.Layout_menuOpen__Oqe1u {
		max-width: 240px;
		height: 100vh;
		background: var(--body-fill-rest);
	}
.Layout_menu__2Y5Oo.Layout_menuOpen__Oqe1u .Layout_menuList___E7Wy {
			left: 0;
		}
.Layout_menu__2Y5Oo ul {
		padding: 1em;
		list-style: none;
		margin: 0;
		position: fixed;
		top: 0;
		left: 0;
	}
.Layout_main__maYPe {
	height: 100vh;
}
.Layout_main__maYPe.Layout_menuCollapsed__maRss {
		grid-template-columns: 52px 1fr;
	}
@media screen and (min-width: 768px) {
.Layout_main__maYPe {
		display: grid;
		grid-template-columns: 52px 1fr;
		transition: grid-template-columns 0.2s
}
	}
@media screen and (min-width: 960px) {
.Layout_main__maYPe {
		display: grid;
		grid-template-columns: 240px 1fr;
		transition: grid-template-columns 0.2s
}
	}
.Layout_appBar__WcDi7 {
	flex: 0;
	display: grid;
	align-items: center;
	padding: 5px calc(var(--design-unit) * 2px) 5px 52px;
	gap: calc(var(--design-unit) * 2px);
	grid-template-columns: max-content auto max-content;
}
@media screen and (min-width: 768px) {
.Layout_appBar__WcDi7 {
		padding-left: 5px
}
	}
.Layout_appBar__WcDi7 .Layout_authButtons__0fB_B {
		margin-left: auto;
		display: flex;
	}
.Layout_title__MSDpw {
	font-size: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.Layout_backButton__QWc6J {
	max-width: 0;
	transition: max-width 0.2s;
	overflow: hidden;
	flex-shrink: 0;
	box-sizing: border-box;
}
.Layout_backButton__QWc6J.Layout_visibleBackButton__0tuKY {
		max-width: 45px;
	}
.Layout_backButton__QWc6J button {
		min-width: auto;
	}
.Layout_backButton__QWc6J button svg {
			margin: 0 !important;
		}
.Layout_content__J5pnr {
}

