* {
	box-sizing: border-box;
}

.is-modal-popup-open {
	overflow: hidden;

	body {
		overflow: hidden;
		min-height: 100%;
	}
}

.modal-popup-form {
	margin: 0 50px;
}

.modal-popup-buttons {
	border-top: 1px solid var(--neutral-stroke-rest);
	padding: 15px !important;
	margin-top: 15px !important;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	list-style: none;
}

.modal-popup-content {
	min-height: 50px;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Segoe UI Regular Cyrillic", "Segoe UI", "Segoe WP", Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
		Helvetica Neue, Tahoma, Arial, sans-serif;
	font-size: 14px;
	overflow-x: hidden;
	background: var(--body-fill-rest);
	color: var(--body-foreground-rest);

	button,
	input,
	textarea {
		font-family: "Segoe UI Regular Cyrillic", "Segoe UI", "Segoe WP", Oxygen, Ubuntu, Cantarell, Fira Sans,
			Droid Sans, Helvetica Neue, Tahoma, Arial, sans-serif;
	}

	ul,
	ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	p {
		font-size: 14px;
	}

	h1 {
		font-weight: normal;
		font-size: 28px;
	}

	h2 {
		font-weight: normal;
		font-size: 25px;
	}

	h3 {
		font-weight: normal;
		font-size: 18px;
	}

	h5,
	h6 {
		display: inline;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
	}

	a {
		@mixin link;
	}
}

a {
	color: var(--accent-fill-rest);
	text-decoration: none;

	&:hover {
		color: var(--accent-fill-hover);
	}

	&:active {
		color: var(--accent-fill-active);
	}
}

h1,
h2,
h3 {
	:global(.subTitle) {
		font-size: 14px;
		font-style: italic;

		p {
			margin: -0.1em 0;
		}
	}
}

h1 {
	font-size: 20pt;
	margin: 0.67em 0 0.2em 0;
}

h2 {
	font-size: 15pt;
	margin: 0.67em 0 0.2em 0;
}

h3 {
	font-size: 12pt;
	margin: 0.67em 0 0.2em 0;
}

h4 {
	font-size: 12pt;
	margin: 0.67em 0 0.2em 0;
	font-weight: bold;
}

p {
	margin: 0.5em 0;
}

h1 + p,
h2 + p,
h3 + p,
ul + p,
ol + p,
div + p {
	text-indent: 0;
}

.mdTable {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;

	tr {
		background: var(--neutral-fill-alt-rest);

		&:nth-child(even) {
			background: transparent;
		}

		td,
		th {
			padding: calc(var(--design-unit) * 2px);
		}
	}

	thead tr {
		background: transparent !important;
	}
}
