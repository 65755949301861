.wrapper {
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background: var(--neutral-fill-rest);
	border: 1px solid var(--neutral-stroke-alt-rest);
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		transition-property: fill;
		transition-duration: 200ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
		fill: var(--neutral-fill-rest);
	}

	&:hover {
		svg {
			fill: var(--neutral-stroke-alt-rest);
		}
	}

	&.selected,
	&.selected:hover {
		background: var(--accent-fill-rest);
		border-color: var(--neutral-fill-rest);
		svg {
			fill: var(--neutral-fill-rest);
		}
	}
}
