@import "../guideline.css";

@keyframes scaleFadeIn {
	from {
		opacity: 0;
		transform: scale(0.8);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}

.backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--shadow22);
	backdrop-filter: blur(2px);
	z-index: 999;
}

.wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: var(--base-modal-z-index);
	width: 100%;
	height: 100%;
	overflow-x: auto;
	overflow-y: auto;
	text-align: center;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	opacity: 1;
	white-space: nowrap;

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;
	}

	.popup {
		background: var(--neutral-fill-rest);
		position: relative;
		box-sizing: border-box;
		text-align: left;
		white-space: normal;
		z-index: 10050;
		animation: scaleFadeIn 0.4s ease;
		width: 100%;
		display: flex;
		margin: 0;
		@mixin shadowDepth4;
		border-radius: 0;
		border-width: 0;

		@media (--tablet) {
			width: 80%;
			margin: 30px 0;
			border-radius: calc(var(--layer-corner-radius) * 1px);
			border-width: 1px;
		}

		* {
			box-sizing: border-box;
		}
	}

	.popupMain {
		width: 100%;
	}

	.close {
		cursor: pointer;
		position: absolute;
		color: var(--neutral-foreground-rest);
		display: block;
		top: 20px;
		right: 20px;
		opacity: 1;
		background: transparent;
		padding: 0;
		border: none;
		z-index: 2;

		svg {
			fill: var(--neutral-foreground-rest);
		}
	}
}

.header {
	@mixin subtitle;
	margin-top: 0;
	margin-bottom: 12px;
	padding-right: 36px;
}

.content {
	width: 100%;
}
