@import "../../guideline.css";

.wrapper *,
.popover * {
	box-sizing: border-box;
}

.popover {
	@mixin shadowDepth2;
	background: var(--body-fill-rest);
	position: absolute;
	z-index: var(--base-modal-z-index);
	left: 0;
	top: 0;
	width: 0;
	height: auto;
	composes: animateList from "../../animations.css";
	outline: none;
	pointer-events: all;
	padding: calc(var(--design-unit) * 1px);

	&.message {
		padding: calc(var(--design-unit) * 3px);
	}

	&.paddingLess {
		padding: 0;
	}
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.button {
	position: relative;
}

.label {
	color: var(--accent-fill-rest);
	margin-bottom: 5px;
	font-size: 12px;

	&.labelError {
		color: var(--error-foreground);
	}
}

.error {
	font-size: 14px;
	margin-top: 4px;
	color: var(--error-foreground);
	position: relative;
	top: -6px;
}

.popoverButtons > * {
	width: 100%;
}

.chevron {
	margin-inline: calc(var(--design-unit) * 1px) 0 !important;
	transition: 0.3s 0s ease-out;
}

.openChevron {
	transform: rotateZ(180deg);
}
