@import "../guideline.css";

.bigWrapper {
	margin: 100px auto;
}

.loaderWrapper {
	position: relative;
	margin: 0 auto;
	width: 24px;

	&:before {
		content: "";
		display: block;
		padding-top: 100%;
	}
}

.circular {
	animation: rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	stroke: var(--accent-fill-rest);
	animation: dash 1.5s ease-in-out infinite;
	stroke-linecap: round;

	&.buttonTheme {
		stroke: var(--accent-foreground-rest);
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}
