@keyframes css-0 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes css-13 {
	0% {
		transform: translate3d(0px, -20px, 0px);
	}
	100% {
		transform: translate3d(0px, 0px, 0px);
	}
}

@keyframes css-12 {
	0% {
		transform: translate3d(0px, -10px, 0px);
	}
	100% {
		transform: translate3d(0px, 0px, 0px);
	}
}

@keyframes css-16 {
	0% {
		transform: translate3d(-20px, 0, 0px);
	}
	100% {
		transform: translate3d(0px, 0px, 0px);
	}
}

.animateMenu {
	animation-name: css-0, css-12;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

.animateList {
	animation-name: css-0, css-13;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

.animateHorizontal {
	animation-name: css-0, css-16;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

.animateHorizontalRight {
	animation-name: css-0, css-17;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

@define-mixin animateList {
	animation-name: css-0, css-13;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}

@define-mixin animateHorizontal {
	animation-name: css-0, css-16;
	animation-duration: 0.367s;
	animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
	animation-fill-mode: both;
}
