@import "../../guideline.css";

.wrapper {
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
	cursor: pointer;
	user-select: none;
	width: 100%;

	&:focus-visible {
		@mixin insetFocus;
	}

	.checkbox {
		position: relative;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		height: 20px;
		width: 20px;
		border: calc(var(--stroke-width) * 1px) solid var(--neutral-stroke-rest);
		border-radius: calc(var(--control-corner-radius) * 1px);
		box-sizing: border-box;
		transition-property: background, border, border-color;
		transition-duration: 200ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
		overflow: hidden;
		background: var(--neutral-fill-rest);

		.mark {
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				vertical-align: top !important;
				fill: transparent;
			}
		}
	}

	&.checked {
		.checkbox {
			background: var(--accent-fill-rest);
			border-color: var(--accent-fill-rest);
		}

		.mark svg {
			fill: var(--accent-foreground-rest);
		}

		.indeterminateMark {
			height: 1px;
			width: calc(var(--design-unit) * 2px);
			background: var(--accent-foreground-rest);
		}
	}

	&:hover {
		.checkbox {
			background: var(--neutral-fill-hover);
			border-color: var(--neutral-stroke-hover);
		}

		&.checked {
			.checkbox {
				background: var(--accent-fill-hover);
				border-color: var(--accent-fill-hover);
			}

			.mark svg {
				fill: var(--accent-foreground-hover);
			}

			.indeterminateMark {
				background: var(--accent-foreground-hover);
			}
		}
	}

	&:active {
		.checkbox {
			background: var(--neutral-fill-active);
			border-color: var(--neutral-stroke-active);
		}

		&.checked {
			.checkbox {
				background: var(--accent-fill-active);
				border-color: var(--accent-fill-active);
			}

			.mark svg {
				fill: var(--accent-foreground-active);
			}

			.indeterminateMark {
				background: var(--accent-foreground-active);
			}
		}
	}

	&.disabled {
		opacity: var(--disabled-opacity);
		cursor: not-allowed;
	}

	&.disabled .indeterminateMark {
		width: 10px;
		height: 2px;
		background: transparent;
		border-radius: 2px;
		transition: all cubic-bezier(0.4, 0, 0.23, 1) 0.2s;
	}

	> svg {
		flex-shrink: 0;
	}

	.label {
		margin-left: calc(var(--design-unit) * 3px);
		width: 100%;

		&.emptyLabel {
			display: none;
		}
	}
}
