@import "../../guideline.css";

.list {
	display: flex;
	gap: calc(var(--design-unit) * 1px);

	&.vertical {
		width: 100%;
		flex-direction: column;
	}

	&:focus {
		outline: none;
	}
}

.moreButton {
	margin-left: calc(var(--design-unit) * 1px);
}

.selectedMoreButton {
	@mixin normalHorizontalItem;
	@mixin selectedHorizontalItem;
}

.itemWrapper.nestedLevel_1 {
	padding-left: 34px;
}

.itemWrapper.horizontal {
	@mixin normalHorizontalItem;
}

.itemWrapper.vertical {
	@mixin normalVerticalItem;
	width: 100%;

	&.nestedLevel_1::before {
		left: 25px;
	}
}

.itemWrapper:active:not(.hasChild) {
	&.single {
		@mixin activeItem;
	}

	.item {
		color: var(--neutral-stroke-alt-rest);

		svg {
			fill: var(--neutral-stroke-alt-rest);
		}
	}
}

.itemWrapper:focus-visible,
.itemWrapper.focused {
	@mixin insetFocus;
}

.itemWrapper.open.collapsed {
	/* FIXME */
	background-color: var(--body-fill-active);
}

.itemWrapper.selected {
	&.single {
		&.horizontal {
			@mixin selectedHorizontalItem;
		}

		&.vertical {
			@mixin selectedVerticalItem;
		}
	}
}

.item {
	width: 100%;
	display: flex;
	align-items: center;

	a {
		display: flex;
		width: 100%;
		align-items: center;
	}
}

.chevron {
	transition: 0.3s 0s ease-out;
	position: absolute;
	right: 9px;

	.itemWrapper.open & {
		transform: rotateZ(180deg);
	}
}

.subList {
	overflow: hidden;
	margin: 0;
	margin-top: 3px;
	padding: 0;
	display: flex;
	max-height: 0;
	transition: max-height 0.2s;
	flex-direction: column;

	&.open {
		max-height: 1000px;
	}
}

.checkCircle {
	margin-right: calc(var(--design-unit) * 2px);
}

.popupItem .itemWrapper.itemWrapper.itemWrapper.itemWrapper {
	@mixin clearHorizontalItem;
	@mixin normalVerticalItem;

	&.selected.single {
		@mixin selectedVerticalItem;
	}
}
