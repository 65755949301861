.error {
	color: var(--color-error);
}

.formWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.wrapper {
	width: 100%;
	@media (--tablet) {
		width: 370px;
	}
}

.field {
	margin: 15px;
}

.button {
	margin: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.forget {
	margin: 15px;
}
