.wrapper {
	position: relative;

	.icon {
		position: absolute;
		bottom: 0;
		right: 0;
		background: var(--color-secondary);
		width: 20px;
		height: 20px;
		border-radius: 100%;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.dummy svg {
		fill: var(--color-accent-font) !important;
		margin: 0;
	}

	&.size24 .circle {
		width: 24px;
		height: 24px;

		.initials {
			font-size: 10px;
		}

		svg {
			width: 12px;
			height: 12px;
		}
	}

	&.size32 .circle {
		width: 32px;
		height: 32px;

		.initials {
			font-size: 13px;
		}

		svg {
			width: 15px;
			height: 15px;
		}
	}

	&.size48 .circle {
		width: 48px;
		height: 48px;

		.initials {
			font-size: 18px;
		}

		svg {
			width: 20px;
			height: 20px;
		}
	}

	&.size64 .circle {
		width: 64px;
		height: 64px;

		.initials {
			font-size: 28px;
		}

		svg {
			width: 30px;
			height: 30px;
		}
	}

	&.size128 .circle {
		width: 128px;
		height: 128px;

		.initials {
			font-size: 50px;
		}

		svg {
			width: 50px;
			height: 50px;
		}
	}

	.circle {
		border-radius: 50%;
		overflow: hidden;
		perspective: 1px;
		display: inline-block;
		vertical-align: middle;
		flex-shrink: 0;
	}

	.image,
	.initials {
		display: block;
		width: 100%;
		height: 100%;
	}

	.image {
		object-fit: cover;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.initials {
		background: var(--accent-fill-rest);
		color: var(--accent-foreground-rest);
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
