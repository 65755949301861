.loadingWrapper {
	position: relative;
	min-height: 175px;
	height: 100%;
}

.loadingContainer {
	position: absolute;
	top: 50%;
	margin-top: -100px;
	left: 0;
	width: 100%;
	height: 24px;
	z-index: 10;

	.loading {
		position: relative;
		width: 24px;
		height: 24px;
		padding: 13px;
		margin: 0 auto;
		background-color: #fff;
		border-radius: 24px;
		opacity: 0.7;
		text-align: center;
		box-sizing: content-box !important;

		img {
			position: absolute;
			left: 13px;
			top: 13px;
		}

		.loadingText {
			font: 16px Tahoma, sans-serif;
			color: #04375e;
			display: inline-block;
			vertical-align: middle;
			line-height: 1.3em;
			margin: 0 15px;
		}
	}
}
