.input {
	width: 100%;
	height: 45px;
	padding: 16px 0 1px 0;
	font-size: 14px;
	line-height: 24px;
	color: var(--accent-foreground-rest);
	border: none;
	background: transparent;
	outline: none;
	-webkit-appearance: none;
	box-sizing: border-box !important;

	&::-ms-reveal,
	&::-ms-clear {
		display: none;
	}

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	&.disabled {
		color: var(--neutral-fill-secondary-hint) !important;
	}
}

.hasFocus {
	outline: none;
}

.bordered {
	height: 32px;
	padding: 0 calc(var(--design-unit) * 2px + 1px);
	color: var(--neutral-foreground-rest);
}
