@import "../components/guideline.css";
@import "./common.css";

.content {
	height: 100dvh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.menu {
	flex-shrink: 0;
	width: 240px;
	max-width: 0;
	transition: max-width 0.2s;
	list-style: none;
	margin: 0;
	padding: 5px;
	min-height: 100vh;
	z-index: 1101;
	display: flex;
	flex-direction: column;
	gap: 3px;
	position: absolute;
	height: 40px;

	@media (--tablet) {
		position: static;
		left: 0;
		max-width: 52px;
		background: var(--body-fill-rest);

		&.collapsed {
			max-width: 52px;
		}

		.menuList {
			position: static !important;
			padding: 0 !important;
			left: 0;
		}
	}

	@media (--desktop) {
		max-width: 240px;
		height: 100vh;

		&.collapsed {
			max-width: 52px !important;
		}
	}

	.hamburger {
		width: 42px;

		> div {
			justify-content: center !important;
		}
	}

	.menuList {
		position: absolute;
		left: -240px;
		top: 37px;
		padding: 5px;
	}

	&.menuOpen {
		max-width: 240px;
		height: 100vh;
		background: var(--body-fill-rest);

		.menuList {
			left: 0;
		}
	}

	ul {
		padding: 1em;
		list-style: none;
		margin: 0;
		position: fixed;
		top: 0;
		left: 0;
	}
}

.main {
	height: 100vh;

	&.menuCollapsed {
		grid-template-columns: 52px 1fr;
	}

	@media (--tablet) {
		display: grid;
		grid-template-columns: 52px 1fr;
		transition: grid-template-columns 0.2s;
	}

	@media (--desktop) {
		display: grid;
		grid-template-columns: 240px 1fr;
		transition: grid-template-columns 0.2s;
	}
}

.appBar {
	flex: 0;
	display: grid;
	align-items: center;
	padding: 5px calc(var(--design-unit) * 2px) 5px 52px;
	gap: calc(var(--design-unit) * 2px);
	grid-template-columns: max-content auto max-content;

	@media (--tablet) {
		padding-left: 5px;
	}

	.authButtons {
		margin-left: auto;
		display: flex;
	}
}

.title {
	font-size: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.backButton {
	max-width: 0;
	transition: max-width 0.2s;
	overflow: hidden;
	flex-shrink: 0;
	box-sizing: border-box;

	&.visibleBackButton {
		max-width: 45px;
	}

	button {
		min-width: auto;

		svg {
			margin: 0 !important;
		}
	}
}

.content {
}
